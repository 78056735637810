// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-a-propos-js": () => import("./../../src/pages/a-propos.js" /* webpackChunkName: "component---src-pages-a-propos-js" */),
  "component---src-pages-blog-js": () => import("./../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cours-js": () => import("./../../src/pages/cours.js" /* webpackChunkName: "component---src-pages-cours-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-ressources-js": () => import("./../../src/pages/ressources.js" /* webpackChunkName: "component---src-pages-ressources-js" */),
  "component---src-templates-chapter-page-js": () => import("./../../src/templates/chapter-page.js" /* webpackChunkName: "component---src-templates-chapter-page-js" */),
  "component---src-templates-course-page-js": () => import("./../../src/templates/course-page.js" /* webpackChunkName: "component---src-templates-course-page-js" */)
}

